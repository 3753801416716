#map {
  height: 80vh;
  width: 100%;
  border: 3px solid #dcdcdc;
  border-radius: 15px;
  top: 0px;
}

@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

h1 {
  font-size: 3rem;
  font-weight: 700;
  color: #037b8990;
  margin: 0 0 1.5rem;
}

i {
  font-size: 1.3rem;
}

a.btn {
  color: #fff;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 0.2s;
}

a.btn:hover {
  background: #d73851;
  border: 1px solid #d73851;
  color: #fff;
}

.cover {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-form input[type="submit"] {
  color: #fff;
  padding: 0 30px;
  cursor: pointer;
  transition: all 0.2s;
  background: #027b9a;
  border: 0;
}

.flex-form input[type="submit"]:hover {
  background: #027b9a;
  border: 1px solid #004658;
}

.flex-form {
  display: flex;
  z-index: 10;
  position: relative;
  width: 500px;
  box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

.flex-form > * {
  border: 0;
  padding: 0 0 0 10px;
  line-height: 50px;
  font-size: 1rem;
  border-radius: 0;
  outline: 0;
}

input[type="search"] {
  flex-basis: 500px;
  border: 2px solid #ffffff;
  background: #fff;
}

input[placeholder="Where do you want to go?"] {
  color: #027b9a;
}

#madeby {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 25px 100px;
  color: #fff;
}

@media all and (max-width: 800px) {
  body {
    font-size: 0.9rem;
  }

  .flex-form {
    width: 100%;
  }

  input[type="search"] {
    flex-basis: 100%;
  }

  .flex-form > * {
    font-size: 0.9rem;
  }

  h1 {
    font-size: 2rem;
  }

  .cover {
    padding: 20px;
  }

  #madeby {
    padding: 30px 20px;
  }

  #map {
    height: 100vh; /* Full height for mobile */
    border-radius: 8px; /* Smaller border radius for mobile */
  }
}

@media all and (max-width: 360px) {
  header nav li {
    margin: 0 10px;
  }

  .flex-form {
    flex-direction: column;
  }

  input[type="search"] {
    flex-basis: 0;
  }

  label {
    display: none;
  }
}

#layers-switcher {
  margin: 20px;
  right: 10px;
  display: inline-block;
  position: absolute;
  z-index: 1;
}

#layers-switcher select {
  padding: 10px 15px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: white;
  color: #333;
  appearance: none;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

#layers-switcher select:hover {
  border-color: #777;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

#layers-switcher select:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

/* arrow dropdown icon customization */
#layers-switcher::after {
  content: "▼";
  font-size: 12px;
  color: #333;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Adjustments for mobile devices */
@media (max-width: 768px) {
  #layers-switcher select {
    width: 100%;
    padding: 12px;
    font-size: 18px;
  }
}

/* For large screens */
@media (min-width: 1200px) {
  .cover {
    padding: 50px 100px;
  }

  h1 {
    font-size: 4rem; /* Larger font size for bigger displays */
  }

  .flex-form input[type="submit"] {
    font-size: 1rem;
    padding: 10px 40px;
  }
}
