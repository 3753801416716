#slides {
  width: 100%;
  height: 100vh; /* Full height of the viewport */
  top: 0;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slide.active {
  opacity: 1;
}

.static-content {
  position: absolute;
  bottom: 40%;
  color: #eefcff;
  text-align: center;
  font-size: 5rem; /* Base font size for larger screens */
  white-space: nowrap;
  text-shadow: 0 0 6px rgba(231, 242, 255, 0.645);
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.static-content h1 {
  font-size: 75px; /* Base font size for h1 */
  margin-bottom: 10px;
  font-weight: bolder;
  text-align: center;
  color: #004658;
  text-transform: uppercase;
}

.static-content p {
  font-size: 58px; /* Base font size for paragraphs */
  margin-top: 0;
  white-space: wrap;
  text-align: right;
  color: #eefcff;
  width: 70%; /* Maintain some width on larger screens */
  right: 0;
  text-shadow: 0 0 6px #004658;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .static-content {
      font-size: 3rem; /* Adjust font size for medium screens */
      bottom: 30%; /* Raise content position */
  }

  .static-content h1 {
      font-size: 50px; /* Adjust h1 size */
      white-space: wrap;

  }

  .static-content p {
      font-size: 40px; /* Adjust paragraph size */
      width: 80%; /* Allow more width on smaller screens */
      text-align: center; /* Center-align for smaller screens */
  }
}

@media (max-width: 480px) {
  .static-content {
      font-size: 2rem; /* Smaller font size for mobile */
      bottom: 20%; /* Raise content position */
  }

  .static-content h1 {
      font-size: 26px; /* Smaller h1 size */
      white-space: wrap;
      
  }

  .static-content p {
      font-size: 22px; /* Smaller paragraph size */
      width: 80%; /* Full width on mobile */
      text-align: center; /* Center-align for mobile */
  }
}