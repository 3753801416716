/* Import Montserrat font */
@import url('https://fonts.googleapis.com/css?family=Montserrat');

/* Reset styles */
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Montserrat', Arial, Verdana;
}

/* Form styles */
#msform {
  width: 400px;
  margin: 50px auto;
  text-align: center;
  position: relative;
}

#msform fieldset {
  background-image: linear-gradient(135deg, #004658 0%, #027b9a 100%);
  height: 50vh;
  width: 50vw;
  border: none;
  border-radius: 3px;
  padding: 20px 30px;
  width: 80%;
  margin: 0 10%;
  position: relative;
}

/* Hide all except current fieldset */
#msform fieldset {
  display: none;
}

#msform fieldset.active {
  display: block;
}

/* Input styles */
#msform input,
#msform textarea {
  padding: 15px;
  border-radius: 3px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
}

/* Button styles */
#msform .action-button {
  width: 100px;
  background: #27ae60;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 1px;
  cursor: pointer;
  padding: 10px;
  margin: 10px 5px;
  text-decoration: none;
  font-size: 14px;
}

#msform .action-button:hover,
#msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #27ae60;
}

/* Progress bar */
#progressbar {
  margin-bottom: 30px;
  margin-left: 25px;
  overflow: hidden;
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  color: white;
  text-transform: uppercase;
  font-size: 9px;
  width: 33.33%;
  float: left;
  position: relative;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 20px;
  line-height: 20px;
  display: block;
  font-size: 10px;
  color: #333;
  background: white;
  border-radius: 3px;
  margin: 0 auto 5px auto;
}

/* Progress bar connectors */
#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: white;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1;
}

#progressbar li:first-child:after {
  content: none;
}

/* Mark active steps */
#progressbar li.active:before,
#progressbar li.active:after {
  background: #27ae60;
  color: white;
}

.progress {
    height: 20px;
    background-color: #f3f3f3;
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #27AE60;
    transition: width 0.4s ease;
    color: white;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
  }
  
  .confirmation-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 20px;
    text-align: left;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .confirmation-list {
    list-style: none;
    padding: 0;
  }
  
  .confirmation-list li {
    margin-bottom: 10px;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .confirmation-list li strong {
    color: #2C3E50;
    font-weight: 600;
    width: 140px;
    display: inline-block;
  }
  
  .confirmation-list li:last-child {
    border-bottom: none;
  }
  
  .fs-title {
    text-align: center;
    font-size: 22px;
    color: #2C3E50;
    margin-bottom: 20px;
  }
  
  .fs-title + .confirmation-card {
    margin-top: 10px;
  }
  
  .confirmation-card ul li {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
  }
  
  .confirmation-card ul li strong {
    font-size: 17px;
    color: #333;
  }
  