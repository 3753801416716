@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');


.auth-container {
  background-image: linear-gradient(135deg, #004658 0%, #027b9a 100%);
  padding: 3em;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 10%;
  margin-left: 20%;
  position: absolute;
  width: 50vw;
}

.auth-heading {
  font-size: 1.8rem;
  margin-bottom: 1.5em;
  color: #fff;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
}

.auth-form input {
  background: transparent;
  padding: 0.8em;
  border-radius: 30px;
  margin-bottom: 1.5em;
  width: 100%;
}


.auth-button {
  background: #004658;
  color: #027b9a;
  padding: 0.8em 2em;
  border: 2px solid #027b9a;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.3s;
}

.auth-button:hover {
  background: #027b9a;
  color: #004658;
  padding: 0.8em 2em;
  border: 2px solid #004658;
}

.auth-button::placeholder {
  color: #027b9a;
}

.auth-button:hover::placeholder {
  color: #004658;
}

.auth-link {
  display: block;
  margin-top: 1em;
  color: #fff;
  font-size: 0.9rem;
  text-decoration: none;
}

.auth-link:hover {
  text-decoration: underline;
}

.auth-error {
  color: red;
  font-size: 0.9rem;
  margin-top: 1em;
}

.auth-success {
  color: green;
  font-size: 0.9rem;
  margin-top: 1em;
}
