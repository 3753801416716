.body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background-image: url(../public/80911.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#left {
  left: 0px;
}

/*header*/

header {
  position: sticky;
  top: 0;
  z-index: 1;
}
h1 {
  margin: 20px 0;
  color: #fff;
}

.center {
  text-align: center;
}

.nav-wrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 90%;
  height: 80px;
  border-radius: 0 0 15px 15px;
  padding: 0 25px;
  z-index: 2;
  background-image: linear-gradient(135deg, #027b9a 0%, #004658 100%);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 100px;
}

.nav-tabs {
  display: flex;
  font-weight: 600;
  font-size: 18px;
  list-style: none;
}

.btn-logout {
  font-weight: 600;
  font-size: 18px;
  color: #000;
  text-decoration: none;
  background: transparent;
}

.btn-logout:hover {
  font-weight: 600;
  font-size: 18px;
  color: #7ca3e7;
  text-decoration: none;
  background: transparent;
}


.nav-tab a,
.logo-container a {
  font-weight: 600;
  font-size: 18px;
  color: #000;
  text-decoration: none;
}

.nav-tab a:hover,
.logo-container a:hover {
  font-weight: 600;
  font-size: 18px;
  color: #7ca3e7;
  text-decoration: none;
}

.nav-tab:not(:last-child) {
  padding: 10px 25px;
  margin: 0;
  border-right: 1px solid #eee;
}

.nav-tab:last-child {
  padding: 10px 0 0 25px;
}

.nav-tab,
.menu-btn {
  cursor: pointer;
}

.hidden {
  display: none;
}

@media screen and (max-width: 800px) {
  .nav-container {
    position: fixed;
    display: none;
    overflow-y: auto;
    z-index: -1;
    top: 0;
    right: 0;
    width: 280px;
    height: 100%;
    background: #fff;
    box-shadow: -1px 0 2px rgba(0, 0, 0, 0.2);
  }

  .nav-tabs {
    flex-direction: column;
    align-items: flex-end;
    margin-top: 80px;
    width: 100%;
  }

  .nav-tab:not(:last-child) {
    padding: 20px 25px;
    margin: 0;
    border-right: unset;
    border-bottom: 1px solid #f5f5f5;
  }

  .nav-tab:last-child {
    padding: 15px 25px;
  }

  .menu-btn {
    position: relative;
    display: block;
    margin: 0;
    width: 20px;
    height: 15px;
    cursor: pointer;
    z-index: 2;
    padding: 10px;
    border-radius: 10px;
  }

  .menu-btn .menu {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: #111;
  }

  .menu-btn .menu:nth-child(2) {
    margin-top: 4px;
    opacity: 1;
  }

  .menu-btn .menu:nth-child(3) {
    margin-top: 4px;
  }

  #menuToggle:checked + .menu-btn .menu {
    transition: transform 0.2s ease;
  }

  #menuToggle:checked + .menu-btn .menu:nth-child(1) {
    transform: translate3d(0, 6px, 0) rotate(45deg);
  }

  #menuToggle:checked + .menu-btn .menu:nth-child(2) {
    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0;
  }

  #menuToggle:checked + .menu-btn .menu:nth-child(3) {
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }

  #menuToggle:checked ~ .nav-container {
    z-index: 1;
    display: flex;
    animation: menu-slide-left 0.3s ease;
  }
  @keyframes menu-slide-left {
    0% {
      transform: translateX(200px);
    }
    to {
      transform: translateX(0);
    }
  }
}


/*footer*/
.new_footer_area {
  background: #fbfbfd;
  bottom: 0;
}


.new_footer_top {
  padding: 120px 0px 270px;
  position: relative;
    overflow-x: hidden;
}
.new_footer_area .footer_bottom {
  padding-top: 5px;
  padding-bottom: 50px;
}
.footer_bottom {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #7f88a6;
  padding: 27px 0px;
}
.new_footer_top .company_widget p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: #6a7695;
  margin-bottom: 20px;
}
.new_footer_top .company_widget .f_subscribe_two .btn_get {
  border-width: 1px;
  margin-top: 20px;
}
.btn_get_two:hover {
  background: transparent;
  color: #5e2ced;
}
.btn_get:hover {
  color: #fff;
  background: #6754e2;
  border-color: #6754e2;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a:hover, a:focus, .btn:hover, .btn:focus, button:hover, button:focus {
  text-decoration: none;
  outline: none;
}



.new_footer_top .f_widget.about-widget .f_list li a:hover {
  color: #5e2ced;
}
.new_footer_top .f_widget.about-widget .f_list li {
  margin-bottom: 11px;
}
.f_widget.about-widget .f_list li:last-child {
  margin-bottom: 0px;
}
.f_widget.about-widget .f_list li {
  margin-bottom: 15px;
}
.f_widget.about-widget .f_list {
  margin-bottom: 0px;
}
.new_footer_top .f_social_icon a {
  width: 44px;
  height: 44px;
  line-height: 43px;
  background: transparent;
  border: 1px solid #e2e2eb;
  font-size: 24px;
}
.f_social_icon a {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 45px;
  color: #858da8;
  display: inline-block;
  background: #ebeef5;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.ti-facebook:before {
  content: "\e741";
}
.ti-twitter-alt:before {
  content: "\e74b";
}
.ti-vimeo-alt:before {
  content: "\e74a";
}
.ti-pinterest:before {
  content: "\e731";
}

.btn_get_two {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #5e2ced;
  border-color: #5e2ced;
  color: #fff;
}

.btn_get_two:hover {
  background: transparent;
  color: #5e2ced;
}

.new_footer_top .f_social_icon a:hover {
  background: #5e2ced;
  border-color: #5e2ced;
color:white;
}
.new_footer_top .f_social_icon a + a {
  margin-left: 4px;
}
.new_footer_top .f-title {
  margin-bottom: 30px;
  color: #263b5e;
}
.f_600 {
  font-weight: 600;
}
.f_size_18 {
  font-size: 18px;
}
h1, h2, h3, h4, h5, h6 {
  color: #4b505e;
}
.new_footer_top .f_widget.about-widget .f_list li a {
  color: #6a7695;
}


.new_footer_top .footer_bg {
  position: absolute;
  bottom: 0;
  background: url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEigB8iI5tb8WSVBuVUGc9UjjB8O0708X7Fdic_4O1LT4CmLHoiwhanLXiRhe82yw0R7LgACQ2IhZaTY0hhmGi0gYp_Ynb49CVzfmXtYHUVKgXXpWvJ_oYT8cB4vzsnJLe3iCwuzj-w6PeYq_JaHmy_CoGoa6nw0FBo-2xLdOPvsLTh_fmYH2xhkaZ-OGQ/s16000/footer_bg.png") no-repeat scroll center 0;
  width: 100%;
  height: 266px;
}

.new_footer_top .footer_bg .footer_bg_one {
  background: url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEia0PYPxwT5ifToyP3SNZeQWfJEWrUENYA5IXM6sN5vLwAKvaJS1pQVu8mOFFUa_ET4JuHNTFAxKURFerJYHDUWXLXl1vDofYXuij45JZelYOjEFoCOn7E6Vxu0fwV7ACPzArcno1rYuVxGB7JY6G7__e4_KZW4lTYIaHSLVaVLzklZBLZnQw047oq5-Q/s16000/volks.gif") no-repeat center center;
  width: 330px;
  height: 105px;
background-size:100%;
  position: absolute;
  bottom: 0;
  left: 30%;
  -webkit-animation: myfirst 22s linear infinite;
  animation: myfirst 22s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_two {
  background: url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhyLGwEUVwPK6Vi8xXMymsc-ZXVwLWyXhogZxbcXQYSY55REw_0D4VTQnsVzCrL7nsyjd0P7RVOI5NKJbQ75koZIalD8mqbMquP20fL3DxsWngKkOLOzoOf9sMuxlbyfkIBTsDw5WFUj-YJiI50yzgVjF8cZPHhEjkOP_PRTQXDHEq8AyWpBiJdN9SfQA/s16000/cyclist.gif") no-repeat center center;
  width: 88px;
  height: 100px;
background-size:100%;
  bottom: 0;
  left: 38%;
  position: absolute;
  -webkit-animation: myfirst 30s linear infinite;
  animation: myfirst 30s linear infinite;
}



@-moz-keyframes myfirst {
0% {
  left: -25%;
}
100% {
  left: 100%;
}
}

@-webkit-keyframes myfirst {
0% {
  left: -25%;
}
100% {
  left: 100%;
}
}

@keyframes myfirst {
0% {
  left: -25%;
}
100% {
  left: 100%;
}
}

/*************footer End*****************/

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #007bff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

p {
  font-size: 18px;
  margin-top: 10px;
  color: #555;
}


@media screen and (max-width: 768px) {
  .nav-wrapper {
    flex-direction: column;
    height: auto;
    padding: 10px 0;
  }

  .nav-tabs {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .nav-tab {
    padding: 10px 0;
  }
}

/* Responsive Design for Footer */
.new_footer_top {
  padding: 50px 0px 100px;
}

@media screen and (max-width: 768px) {
  .new_footer_top {
    padding: 30px 15px;
  }

  .new_footer_top .company_widget p {
    font-size: 14px;
  }

  .f_social_icon a {
    width: 36px;
    height: 36px;
    line-height: 36px;
  }
}

@media screen and (max-width: 480px) {
  .nav-tabs {
    font-size: 14px;
  }

  .btn_get_two {
    padding: 10px 20px;
    font-size: 14px;
  }
  

}

/* Spinner for loading (Responsive) */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
}

.spinner {
  width: 40px;
  height: 40px;
}

/* Add media query for smaller devices */
@media screen and (max-width: 480px) {
  h1 {
    font-size: 24px;
  }

  p {
    font-size: 14px;
  }
}
