.not-found-container {
  text-align: center;
  padding: 50px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-found-container h1 {
  font-size: 100px;
  margin-bottom: 20px;
  color: #FF6347; /* Red-Tomato color */
}

.not-found-container p {
  font-size: 18px;
  margin-bottom: 30px;
}

.home-link {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #027b9a;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.home-link:hover {
  background-color: #025f7a;
}

@media (max-width: 768px) {
  .not-found-container h1 {
    font-size: 70px; /* Reduce font size on tablets */
  }
  
  .not-found-container p {
    font-size: 16px;
  }

  .home-link {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .not-found-container h1 {
    font-size: 50px; /* Further reduce on mobile */
  }
  
  .not-found-container p {
    font-size: 14px;
  }

  .home-link {
    font-size: 12px;
    padding: 6px 12px;
  }
}
