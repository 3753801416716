.dashboard-container {
  padding: 20px;
}

.shop-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.shop-card {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 200px;
  cursor: pointer;
  transition: transform 0.2s;
}

.shop-card:hover {
  transform: scale(1.05);
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal-content {
  background-color: #fff;
  background-image: url(../../public/as.avif);
  padding: 20px;
  border-radius: 10px;
  width: 80%; /* adjusted width to allow scrolling */
  height: 80vh; /* set height to enable scrolling */
  position: relative;
  overflow-y: auto; /* enable vertical scrolling */
  z-index: 1;
}

.modal-content input {
  margin: 2px;
  text-align: left;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}

/* Global container padding for small screens */
@media (max-width: 768px) {
  .dashboard-container {
    padding: 10px;
  }

  .modal-content {
    width: 95%;
    height: 70vh;
    padding: 10px;
  }

  .dashboard {
    width: 100vw;
    margin-left: 0;
    padding: 15px;
  }

  .product-cards-container {
    flex-direction: column;
    gap: 15px;
  }

  .shop-card,
  .product-card {
    width: 100%;
    max-width: 100%;
  }

  .product-card {
    flex-direction: column;
    text-align: center;
  }

  .product-image {
    width: 80px;
    height: 80px;
  }

  .product-details-column {
    padding-left: 0;
  }

  .shop-card {
    padding: 15px;
  }
}

/* Adjustments for tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .dashboard-container {
    padding: 15px;
  }

  .dashboard {
    width: 90vw;
    margin-left: 5%;
  }

  .shop-card {
    width: 45%;
  }

  .modal-content {
    width: 90%;
    height: 75vh;
    padding: 15px;
  }

  .product-card {
    width: 100%;
    flex-direction: row;
  }

  .product-image {
    width: 90px;
    height: 90px;
  }
}

/* Large screens */
@media (min-width: 1025px) {
  .dashboard-container {
    padding: 20px;
  }

  .dashboard {
    width: 80vw;
    margin-left: 10%;
  }

  .shop-card {
    width: 200px;
  }

  .product-card {
    width: 100%;
    max-width: 500px;
    flex-direction: row;
  }

  .product-image {
    width: 100px;
    height: 100px;
  }

  .product-details-column {
    padding-left: 20px;
  }
}

/* General responsiveness for all elements */
@media (max-width: 480px) {
  h2,
  .product-ti {
    font-size: 24px;
  }

  .product-stock,
  .product-price {
    font-size: 18px;
  }

  .shop-card {
    padding: 10px;
  }

  .modal-content {
    padding: 10px;
    height: 60vh;
    overflow-y: auto;
  }

  .product-image {
    width: 70px;
    height: 70px;
  }

  .product-des {
    font-size: 18px;
  }
}

h3.mt-4 {
  margin-top: 20px;
}

.product-form {
  display: flex;
  flex-direction: column;
}

.product-form input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.product-form button {
  padding: 10px;
  background-color: #27ae60;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.product-form .delete-button {
  background-color: #e74c3c;
}

.preview-img {
  margin-top: 10px;
  max-width: 100px;
}

.tit {
  color: #27ae60;
  text-transform: uppercase;
}

.dashboard {
  padding: 30px;
  background-image: linear-gradient(135deg, #00465849 0%, #027c9a64 100%);
  width: 80vw;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 10%;
  margin-left: 10%;
  position: absolute;
}

.shop-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.shop-card {
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.shop-card:hover {
  transform: translateY(-5px);
}

.shop-card h3 {
  margin-bottom: 10px;
  text-align: center;
}

.shop-card p {
  margin-bottom: 5px;
  text-align: left;
}

h2 {
  margin-bottom: 3%;
  text-transform: uppercase;
  font-size: 36px;
  font-weight: bold;
  color: #28a745;
}

.product-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.product-card {
  background-image: linear-gradient(135deg, #00465849 0%, #027c9a64 100%);
  border: 1px solid #ddd;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  width: 100%;
  max-width: 500px;
  padding: 20px;
  cursor: pointer;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-image-column {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-details-column {
  flex: 2;
  padding-left: 20px;
}

.product-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

input[type="text"],
input[type="file"],
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 14px;
}

/* Add hover effect to inputs */
input[type="text"]:focus,
textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 3px rgba(0, 123, 255, 0.3);
}

.space {
  margin-top: 10px;
  padding-left: 10px;
}

.product-price {
  font-size: 26px;
  font-weight: bold;
  color: #28a745;
  text-align: left;
}

.currency-icon {
  margin-right: 5px;
}

/* Stock label */
.product-stock {
  font-size: 20px;
  color: #2600ff; /* Red for low stock */
  text-align: left;
  font-weight: normal;
}

.product-ti {
  margin-top: 4%;
  font-size: 36px;
  font-weight: bold;
  color: #28a745;
  text-transform: uppercase;
}

.product-des {
  margin-bottom: 4%;
  font-size: 25px;
  font-style: italic;
  background-color: #004658;
  color: #027b9a;
  box-shadow: 0 0 3px rgba(0, 123, 255, 0.3);
  padding: 10px;
  border: 2px #027b9a dashed;
  border-radius: 15px;
  text-align: left;
}

.product-des:hover {
  background-color: #027b9a;
  color: #004658;
  border: 2px #004658 dashed;
  transition: cubic-bezier(1, 0, 0, 1);
}
