/* Common Auth Container */

  
  .auth-card {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 1.5em;
    border-radius: 15px;
  }
  
  .auth-card .card-title, .auth-card .card-text {
    color: #fff;
  }
  
 
  .auth-success {
    color: green;
    font-size: 1rem;
  }
  
  .auth-error {
    color: red;
    font-size: 1rem;
  }
  
  .ml-2 {
    margin-left: 1em;
  }
  
  .profile-info {
    display: flex;
    flex-direction: column;
    gap: 1.2em;  /* Adds space between each profile item */
  }
  
  .info-item {
    display: flex;
    justify-content: space-between;  /* Aligns the label and value horizontally */
    align-items: center;
  }
  
  .info-item .card-title {
    font-weight: bold;
    margin: 0;
    color: #fff;
  }
  
  .info-item .ti {
    font-weight: normal;
    margin: 0;
    color: #dcdcdc;
  }
  
  .verification-buttons {
    margin-top: 1.5em;
    display: flex;
    justify-content: flex-start;
    gap: 1em;  /* Space between buttons */
  }
  

.ver {
    margin-top: 20px;
}

/* General Styles */
.profile-container {
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  

  
  /* Modal Backdrop */
  .custom-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Styling */
  .custom-modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
  }
  
  .custom-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .custom-modal-header h3 {
    margin: 0;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .custom-modal-body {
    margin: 20px 0;
  }
  
  .verification-input {
    width: 90%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #027b9a;
    color: #027b9a;
    background: #004658;
    border-radius: 4px;
  }

  .verification-input::placeholder {
    color: #027b9a;
  }

  .verification-input:hover::placeholder {
    color: #004658;
  }

  .verification-input:hover {
    border: 1px solid #004658;
    color: #004658;
    background: #027b9a;
    border-radius: 4px;
  }
  
  .custom-modal-footer {
    display: flex;
    justify-content: space-between;
  }
  
  .cancel-button {
    border: 1px solid #004658;
    color: #004658;
    background: #027b9a;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .cancel-button:hover {
    border: 1px solid #027b9a;
    color: #027b9a;
    background: #004658;
  }
  

  @media (max-width: 768px) {
    .auth-card {
      padding: 1em;
    }
  
    .info-item {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5em;
    }
  
    .custom-modal {
      width: 95%;
      max-width: none;
    }
  
    .verification-input {
      width: 100%;
      padding: 12px;
    }
  
    .verification-buttons {
      flex-direction: column;
    }
  
    .cancel-button {
      padding: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .auth-card {
      padding: 0.8em;
    }
  
    .info-item {
      font-size: 0.9rem;
    }
  
    .custom-modal {
      padding: 15px;
      width: 100%;
    }
  
    .verification-input {
      padding: 10px;
      font-size: 0.9rem;
    }
  
    .verification-buttons {
      gap: 0.5em;
    }
  
    .cancel-button {
      padding: 8px 15px;
      font-size: 0.9rem;
    }
  }
  